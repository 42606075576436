import React, { useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import { Button, Card, Grid, TextField, useMediaQuery } from '@material-ui/core'
import voltplus from '../assets/vpluslogo.png'
import background from '../assets/homeBackground.jpg'
import { useHistory } from 'react-router-dom'
import apis from '../api/api'
import { theme } from '../index'

const useStyles = makeStyles({
  form: {
    width: '100%' // Fix IE 11 issue.
  },
  card: {
    borderRadius: 19,
    border: '0.25px solid #DCDCDC',
    boxShadow: 'none'
  }
})

function AuthPage() {
  const [errormsg, setErrorMsg] = React.useState()
  const mobile = useMediaQuery(theme.breakpoints.down('md'))
  const history = useHistory()

  const authCheck = () => {
    localStorage.removeItem('custom-theme')
    const token = localStorage.getItem('token')
    if (token) {
      apis.tokenCheck().then(r => {
        if (r.data.isLoggedIn) {
          history.push('/dash')
        } else {
          history.push('/')
        }
      })
    }
  }
  useEffect(() => {
    authCheck()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleSubmit = login => {
    login.preventDefault()
    const user = {
      email: login.target[0].value.toLowerCase(),
      password: login.target[2].value
    }
    apis.login(user).then(r => {
      if (r.data.token) {
        localStorage.removeItem('custom-theme')
        localStorage.setItem('token', r.data.token)
        window.location.reload()
      } else {
        setErrorMsg('Invalid login')
        localStorage.removeItem('token')
        localStorage.removeItem('persist:root')
        sessionStorage.removeItem('persist:root')
        sessionStorage.removeItem('token')
        localStorage.removeItem('custom-theme')
      }
      authCheck()
    })
  }
  const classes = useStyles()
  return (
    <Box>
      <Box width={mobile ? 300 : 500} height={250} ml={'12%'} mt={mobile ? '12%' : '7%'} mb={12}>
        <Card className={classes.card}>
          <Box mt={4} mb={2} ml={5}>
            <img src={voltplus} alt="voltpluslog" height="60px" />
          </Box>
          <Box mx={4} mt={2} mb={3}>
            <form className={classes.form} onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    id="email"
                    label="Email"
                    name="email"
                    autoComplete="email"
                    //inputRef={register({ required: true, pattern: emailRegex })}
                    error={errormsg}
                    helperText={errormsg}
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    name="password"
                    label="Password"
                    type="password"
                    id="password"
                    autoComplete="current-password"
                    //inputRef={register({ required: true })}
                    error={errormsg}
                    helperText={errormsg}
                  />
                </Grid>
              </Grid>
              <Box mt={5} mb={2}>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                  //disabled={formState.isSubmitting}
                >
                  {'login'}
                </Button>
              </Box>
            </form>
          </Box>
        </Card>
      </Box>
      <Box ml="14%">
        <img src={background} alt="loginBgr" />
      </Box>
    </Box>
  )
}

export default AuthPage

